<template>
  <div>
    <canvas v-if="!arePerfsEmpty" :id="`sparkline-${entity}-${id}`" width="70" height="30"></canvas>
    <span v-else class="btn badge badge-secondary">No Data</span>
  </div>
</template>

<script>
export default {
  props: {
    entity: String,
    id: Number,
    perfs: Array
  },
  name: 'TinyPerf',
  data() {
    return {
      chart: {}
    }
  },
  mounted() {
    this.displayChart();
  },
  watch: {
    perfs: function() {
      // this.reloadChart()
    }
  },
  computed: {
    arePerfsEmpty() { 
      return this.perfs.every(val => val === 0)
    },
    color() {
      const { perfs } = this;
      const len = perfs.length;

      if (perfs[len - 2] > perfs[len - 1])
        return '#dc3545';

      return '#27c414'
    }
  },
  methods: {
    displayChart() {      
      const { id, entity, perfs } = this    

      let sl = document.getElementById(`sparkline-${entity}-${id}`)
      if (!sl) return;

      sl = sl.getContext('2d');

      if (this.arePerfsEmpty) return;

      let chart = new Chart(sl, {
        type: 'line',
        data: {
          labels: Array(perfs.length).fill(0),
          datasets: [
            {
              data: this.perfs
            }
          ]
        },
        options: {
          animation: {
            duration: 0,
          },
          hover: {
            animationDuration: 0,
          },
          responsiveAnimationDuration: 0,
          responsive: false,
          legend: {
            display: false
          },
          elements: {
            line: {
              borderColor: this.color,
              borderWidth: 2,
              backgroundColor: this.color + "5f"
            },
            point: {
              radius: 0
            }
          },
          tooltips: {
            enabled: false
          },
          scales: {
            yAxes: [
            {
              display: true,
              ticks: {
                min: Math.min(...perfs, -0.05),
                max: Math.max(...perfs, 0.05),
                display: false,
                beginAtZero: true
              }
            }
            ],
            xAxes: [
            {
              display: false,
              gridLines: {
                display: false,
              },
              ticks: {
                display: false
              }
            }
            ]
          }
        }
      });

      this.chart = chart;
    },
    // reloadChart() {

    // }
  }
}

</script>

<style>

</style>