<template>
  <tbody>
    <tr :key="adset.name" v-for="adset in adsets" :class="colorPick(adset)">      
      <td>
        <span :class="`campaign-status ${adset.status}`" @click="toggleStatus(adset)"><i :class="`fas ${statusIcons(adset.status)}`"></i></span>
      </td>      
      <td class="chart-td align-middle" @click="openPerfModal(adset)">
        <TinyPerf v-if="adset.tiny_perf" :id="adset.id" entity="adset" :perfs="adset.tiny_perf"></TinyPerf>
      </td>
      <td>
      </td>
      <td>
      </td>
      <td :class="`th2 text-left ${colorPick(adset)}`">
        <div class="adset-name">
          <span class="ml-3 badge multi-adsets badge-pill"><i class="fal fa-circle"></i></span>
          <div class="name-text">{{ adset.name }}</div>
          <div class="adset-icons mr-4">
            <i :class="`fas fa-${adset.gender}`"/>
            <i class="ap-icon" v-if="adset.auto_placement">AP</i>
            <country-flag v-if="adset.countries && adset.countries.length == 1 && adset.countries[0] != 'all'" :country='adset.countries[0]' size='small'/>
            <i v-else-if="adset.countries" class="far fa-globe-americas"></i>
            <i v-b-tooltip.hover v-if="adset.devices && adset.devices.length > 0" :title='getTitleForDevice(adset.devices)' :class="`devices ${getClassForDevice(adset.devices)}`"></i>
          </div>
        </div>
      </td>
      <td>
        <div v-if="adset.max_bid || isfieldInEdit(adset.id, 'max_bid')" class="budgets-cell input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="text" :disabled="!isfieldInEdit(adset.id, 'max_bid')" class="form-control" v-model="adset.max_bid" v-on:keyup.enter="saveBudgets(adset, 'max_bid')" />
          <i class="edit-btn fas fa-pencil text-secondary" v-if="!isfieldInEdit(adset.id, 'max_bid')" @click="editBudgets(adset, 'max_bid')"></i>
          <template v-else-if="isfieldInEdit(adset.id, 'max_bid')">
            <i class="edit-btn fas fa-check text-secondary" @click="saveBudgets(adset, 'max_bid')"></i>
            <i class="edit-btn fas fa-history text-secondary" @click="revertBudgets(adset, 'max_bid')"></i>
          </template>
        </div>
      </td>
      <td>
        <div v-if="(adset.daily_budget || isfieldInEdit(adset.id, 'daily_budget')) && (platformOptions.daily_budget ||  {}).adset" class="budgets-cell input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="text" :disabled="!isfieldInEdit(adset.id, 'daily_budget')" class="form-control" v-model="adset.daily_budget" v-on:keyup.enter="saveBudgets(adset, 'daily_budget')" />
          <i class="edit-btn fas fa-pencil text-secondary" v-if="!isfieldInEdit(adset.id, 'daily_budget') && campaignIsRunning" @click="editBudgets(adset, 'daily_budget')"></i>
          <template v-else-if="isfieldInEdit(adset.id, 'daily_budget')">
            <i class="edit-btn fas fa-check text-secondary" @click="saveBudgets(adset, 'daily_budget')"></i>
            <i class="edit-btn fas fa-history text-secondary" @click="revertBudgets(adset, 'daily_budget')"></i>
          </template>
        </div>
      </td>
      <template v-for="col in selectedColumnsData">
        <td :key="col.name" :class="col.cssClass ? col.cssClass(adset) : ''">

          <template v-if="col.name == 'revenue'">
            <b-button @click="loadRevenues(adset.id)" :id="`popover-${adset.id}`" variant="primary" class="revenu-btn">{{ col.format(adset) }}</b-button>
            <b-popover :target="`popover-${adset.id}`" triggers="hover focus" custom-class="popover-custom-body">
              <table>
                <tbody>
                  <tr :key="key + value.id" v-for="(value, key) in revenues">
                    <td>
                      {{ key }}
                    </td>
                    <td>
                      {{ numeral(value).format('$0,0.000') }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-popover>
          </template>

          <span v-else>{{ col.format(adset) }}</span>
          <template v-if="col.customElement">
            <span :class="col.customElement.cssClass ? col.customElement.cssClass(adset) : '' "> {{ col.customElement.format(adset) }}</span>
          </template>
        </td>
      </template>
    </tr>
    <PerfModal ref="perfModal" entityType="adset" :entity="currentAdset" :selectedDate="{ end: end, start: start }" :perfRangeOptions="perfRangeOptions" :timezone="timezone" :notifications="notifications"/>
  </tbody>
</template>
<script>
  import TinyPerf from './TinyPerf.vue'
  import PerfModal from '../perf/PerfModal.vue'
  import CountryFlag from 'vue-country-flag';
  import numeral from 'numeral'
  import { VALIDATION } from '../../utils/campaign_form.js'
  import { ICONS, DEVICES_DISPLAY } from '../../utils/overview.js'
  import { COLUMNS } from '../../utils/settings/columns.js'
  import { alog, ilog, elog } from '../../utils/logger/logger.js'


  export default {
    name: "AdsetRow",
    props: { 
      campaignId: Number,
      campaignIsRunning: Boolean,
      start: Object,
      end: Object,
      alertErrorSetter: Function,
      platformOptions: Object,
      platform: String,
      refreshCampaignLastChanged: Function,
      perfRangeOptions: Object,
      timezone: String,
      notifications: Number,
      selectedColumns: Array,
      selectedColumnsData: Array
    },
    data: function() {
      return {
        edit: {},
        numeral: numeral,
        previousVal: null,
        revenues: {},
        adsets: [
        { name: "Loading...", id: "1" }
        ],
        currentAdset: null,
      };
    },
    mounted() {
      this.loadAdsets()
    },
    methods: {
      statusIcons(status) {
        return ICONS.status[status]
      },
      openPerfModal(adset) {
        this.currentAdset = adset;
        this.$nextTick(() => {
          this.$refs.perfModal.show()
        })
      },
      toggleStatus(adset) {
        if(!adset.status.match(/running|paused/)) return;

        let origStatus  = adset.status;
        let newStatus   = origStatus === 'running' ? 'paused' : 'running';
        if (confirm(`Do you really want to change ${adset.name} to ${newStatus}?`)) {
          adset.status = 'pending';
          alog(`Adset status change (camp ${this.campaignId}, Adset(${adset.id}))`, adset.status, newStatus);
          this.$http.put(`${this.host}/adsets/${adset.id}/update_status`, {
            status: newStatus
          }).then(res => {
            adset.status          = res.data.status;
            this.alertType        = 'info';
            this.alertError       = 'Updating adset status changed successfully';
            this.dismissCountDown = this.dismissSecs;
            ilog(`${this.alertError} (camp ${this.campaignId}, adset ${adset.status})`)
          }).catch(err => {
            adset.status          = origStatus;
            this.alertType        = 'danger';
            this.alertError       = 'Updating adset status failed';
            this.dismissCountDown = this.dismissSecs;
            elog(`${this.alertError} (camp ${adset.campaign_id}, adset ${adset})`, err)
          })
        }
      },
      loadRevenues(adset_id) {
        this.revenues = {};
        this.$http.get(`${this.host}/campaigns/${this.campaignId}/adsets/${adset_id}/revenues`, {
          params: {
            publisher_id: this.publisher_id,
            start: this.start,
            end: this.end
          }
        }).then(res => {
          this.revenues = res.data.revenues
        })
      },
      async loadAdsets() {
        let res = await this.$http.get(`${this.host}/campaigns/${this.campaignId}/adsets/performance`, {
          params: {
            start: this.start,
            end: this.end,
            columns: this.selectedColumns.filter(COLUMNS.isColMetricsAgg)
          }
        })

        this.adsets = res.data.performance

        const { budgets } = res.data
        if (Object.keys(budgets) == 0 || !this.campaignIsRunning) return

        this.adsets.forEach(adset => {
          let budget = budgets[adset.id]
          adset.max_bid = budget.max_bid
          adset.daily_budget = budget.daily_budget
        })
      },
      colorPick(adset) {
        if (this.edit.adset == adset.id) {
          return 'edit-mode'
        } else {
          return 'open-adsets'
        }
      },
      isfieldInEdit(id, _attr) {
        const { attr, adset } = this.edit
        return adset == id && attr == _attr
      },
      saveBudgets(adset, attr) {
        const text = attr == 'max_bid' ? 'Bid' : 'Adset daily budget'
        alog(`${text} Update (camp ${this.campaignId}, adset ${adset.id})`, this.previousVal, adset[attr])

        if (attr == 'max_bid') {
         if (!VALIDATION.bid(adset[attr])) return;
       } else {
         if (!VALIDATION.budget(adset[attr], this.platform, true)) return;
       }

       let alertError, alertType;
       this.$http.put(`${this.host}/adsets/${adset.id}/update_budgets`, {
        attribute: attr,
        value: adset[attr]
      }).then(res => {
        alertError = name + 'updated successfully';
        alertType = 'success';
        ilog(`${alertError} (camp ${this.campaignId}, adset ${adset.id})`)
        this.edit = {};
        this.refreshCampaignLastChanged(res.data)
      }).catch(ex => {
        alertError = ex.response.data.error;
        alertType = 'danger';
        elog(`${alertError} (camp ${this.campaignId}, adset ${adset.id})`, ex)
      }).finally(() => {
        this.alertErrorSetter(alertError, alertType)
      });
    },
    revertBudgets(adset, attr) {
      adset[attr] = this.previousVal
      this.edit = {}
    },
    editBudgets(adset, attr) {
      if (this.edit.adset) this.revertBudgets(this.adsets.find(a => a.id == this.edit.adset), this.edit.attr)

        this.previousVal = adset[attr];
      this.edit = { adset: adset.id, attr: attr }
    },
    getTitleForDevice(devices) {
      return DEVICES_DISPLAY.title(devices)
    },
    getClassForDevice(devices) {
      return DEVICES_DISPLAY.icon(devices)
    },
  },
  components: {
    TinyPerf,
    PerfModal,
    CountryFlag
  }
};
</script>
<style lang="scss" scoped>

.adset-name
{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;  
  border: none;
  font-size: 14px;
  font-weight: 500;
  min-width: 25vw;
  height: 38px;

  .badge {
    margin-left: 13px !important;
  }

  .name-text {
    flex: 1;
  }

  i.ap-icon {
    font-size: 1rem;
    font-style: unset;
    letter-spacing: -0.1em;
  }

  .adset-icons i{
    margin: 0.2rem;
  }

}

.input-group input,
.input-group span {
  font-size: 13px;
}

.input-group {
  min-width: 150px;
  flex-wrap: nowrap !important;
}

.input-group .form-control {
  width: 60px;
}

.fa-male {
  color: blue
}

.fa-female {
  color: pink
}

@media(min-width: 767px) {
  .adset-name
  {
    white-space: nowrap;
  }
}    

@media(max-width: 767px) {

  .input-container {
    width: 100%;
    padding-bottom: 5px;
  }
  .input-control {
    width: 230px;
    margin-right: auto !important;
  }

  .table td {
    padding: 0.4rem;
  }


  table {
    display: block;
    overflow-x: scroll;
    position: sticky;
    left: 0;
    right: 0;
    height: 80vh;
  }

  .sticky .th{
    top: -1px;
  }

  .search-form {
    padding-top: 0;
  }
}

@media(max-width: 767px) {
  .adset-name {
    max-width: 120px;
    word-wrap: break-word;
  }
}

@media(max-width: 450px) {
  .adset-name {
    max-width: 80px;
    word-wrap: break-word;
  }
}

.revenu-btn,
.revenu-btn:hover {
  border: 0;
  font-size: 13px;
  background: none;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  color: #000;
  font-weight: inherit;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
