<template>
  <b-modal ref="perfModal" size="xl" id="perfModal" hide-footer>
    <template v-if="entity" slot="modal-header">
      <div
        class="d-flex flex-xl-row flex-lg-row flex-column-reverse justify-content-between w-100"
      >
        <div class="d-flex flex-col flex-grow-1 modal-titles">
          <h4>{{ headerName }}</h4>
          <h6>{{ entity.name }}</h6>
          <h6>ID {{ entity.id }}</h6>
        </div>
        <div class="d-flex flex-grow-1 flex-row justify-content-between mb-3">
          <div class="input-container pr-4">
            <label for="period" class="input-control mr-3">Period</label>
            <date-range-picker
              class="form-control"
              v-model="range"
              :options="campaignRangeOptions"
              :key="campaignTimezone"
            />
          </div>
          <div>
            <label for="campaignTimezone" class="input-control title"
              >Timezone</label
            >
            <select
              id="campaignTimezone"
              class="input-field form-control"
              v-model="campaignTimezone"
            >
              <option
                :value="timezone.value"
                v-for="timezone in availableTimezones"
                :key="timezone.value"
              >
                {{ timezone.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </template>
    <div style="height: 300px" class="d-block text-center">
      <canvas id="chart"></canvas>
    </div>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          v-if="entity && entity.platform === 'taboola'"
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'sites' ? 'active' : ''
          }`"
          id="sites"
          @click="changeTab('sites')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >Sites</a
        >
        <a
          v-if="entity && entity.platform === 'taboola'"
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'audiences' ? 'active' : ''
          }`"
          id="audiences"
          @click="changeTab('audiences')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >Audiences</a
        >
        <a
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'history' ? 'active' : ''
          }`"
          id="nav-history-tab"
          @click="changeTab('history')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >History</a
        >
        <a
          v-if="hasMonitizationPresets"
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'performanceTable' ? 'active' : ''
          }`"
          id="nav-performance-table-tab"
          @click="changeTab('performanceTable')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >Presets</a
        >
        <a
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'creatives' ? 'active' : ''
          }`"
          id="nav-creatives-tab"
          @click="changeTab('creatives')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >Creatives</a
        >
        <a
          v-if="entityType === 'campaign'"
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'os-and-browser' ? 'active' : ''
          }`"
          id="nav-os-and-browser-tab"
          @click="changeTab('os-and-browser')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >OS &amp; Browser</a
        >
        <a
          v-if="entityType === 'campaign'"
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'note' ? 'active' : ''
          }`"
          id="nav-note-tab"
          @click="changeTab('note')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >Note<span v-if="hasNote" class="ml-2 badge badge-warning badge-pill"
            ><i class="far fa-sticky-note"></i></span
        ></a>
        <a
          v-if="entityType === 'campaign'"
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'notifications' ? 'active' : ''
          }`"
          id="nav-notifications-tab"
          @click="changeTab('notifications')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >Notifications<span
            v-if="notifications"
            class="ml-2 badge badge-danger badge-pill"
            >{{ notifications }}</span
          ></a
        >
        <a
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'info' ? 'active' : ''
          }`"
          id="nav-info-tab"
          @click="changeTab('info')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >Info</a
        >
        <a
          v-if="entity && entity.platform === 'taboola'"
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'blacklist' ? 'active' : ''
          }`"
          id="blacklist"
          @click="changeTab('blacklist')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >Blacklist</a
        >
        <a
          :class="`nav-item nav-link cursor-pointer ${
            tab === 'activity-log' ? 'active' : ''
          }`"
          id="activity-log"
          @click="changeTab('activity-log')"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          >Activity Log</a
        >
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active modal-table"
        v-if="tab === 'history'"
        role="tabpanel"
        aria-labelledby="nav-history-tab"
      >
        <PerfHistory
          :entityType="entityType"
          :entity="entity"
          :modalSelectedDate="modalSelectedDate"
          :campaignTimezone="campaignTimezone"
          :history="history"
          :totals="totals"
        />
      </div>
      <div
        class="tab-pane fade show active modal-table"
        v-if="tab === 'creatives'"
        role="tabpanel"
        aria-labelledby="nav-creatives-tab"
      >
        <PerfCreatives
          :entityType="entityType"
          :baseUrl="baseUrl"
          :entity="entity"
          :modalSelectedDate="modalSelectedDate"
          :campaignTimezone="campaignTimezone"
        />
      </div>
      <div
        class="tab-pane fade show active modal-table"
        v-if="tab === 'os-and-browser'"
        role="tabpanel"
        aria-labelledby="nav-creatives-tab"
      >
        <PerfOSAndBrowser
          :baseUrl="baseUrl"
          :modalSelectedDate="modalSelectedDate"
          :campaignTimezone="campaignTimezone"
        />
      </div>
      <div
        class="tab-pane fade show active modal-table"
        v-if="tab === 'audiences'"
        role="tabpanel"
        aria-labelledby="nav-audiences-tab"
      >
        <Audiences
          :entityType="entityType"
          :entity="entity"
          :modalSelectedDate="modalSelectedDate"
        />
      </div>
      <div
        class="tab-pane fade show active modal-table"
        v-if="tab === 'sites'"
        role="tabpanel"
        aria-labelledby="nav-audiences-tab"
      >
        <Sites
          :entityType="entityType"
          :entity="entity"
          :modalSelectedDate="modalSelectedDate"
          :campaignTimezone="campaignTimezone"
        />
      </div>
      <div
        class="tab-pane fade show active modal-table"
        v-if="tab === 'blacklist'"
        role="tabpanel"
        aria-labelledby="nav-audiences-tab"
      >
        <CampaignSiteBlacklist
          :entityType="entityType"
          :entity="entity"
          :modalSelectedDate="modalSelectedDate"
        />
      </div>
      <div
        class="tab-pane fade show active modal-table"
        v-if="tab === 'notifications'"
        role="tabpanel"
        aria-labelledby="nav-notifications-tab"
      >
        <PerfNotifications
          :entityType="entityType"
          :entity="entity"
          :notifications="notifications"
        />
      </div>
      <div
        class="tab-pane fade show active"
        v-if="tab === 'note'"
        role="tabpanel"
        aria-labelledby="nav-note-tab"
      >
        <PerfNote
          :entityType="entityType"
          :baseUrl="baseUrl"
          :entity="entity"
        />
      </div>
      <div
        class="tab-pane fade show active"
        v-if="entity && tab === 'info'"
        role="tabpanel"
        aria-labelledby="nav-info-tab"
      >
        Hash Code: {{ entity.hash_code }}
        <br />
        Adsets Codes: {{ entity.adsets_hash_codes }}
        <br />
        Presets Codes: {{ entity.presets_hash_codes }}
      </div>
      <div
        class="tab-pane fade show active modal-table"
        v-if="tab === 'activity-log'"
        role="tabpanel"
        aria-labelledby="nav-activity-log-tab"
      >
        <ActivityLog
          :entityType="entityType"
          :entity="entity"
          :modalSelectedDate="modalSelectedDate"
        />
      </div>
      <div
        class="tab-pane fade show active modal-table"
        v-if="tab === 'performanceTable'"
        role="tabpanel"
        aria-labelledby="nav-performance-table-tab"
      >
        <PerformanceTable
          :key="JSON.stringify(modalSelectedDate)"
          :entityType="entityType"
          :entity="entity"
          :baseUrl="baseUrl"
          :modalSelectedDate="modalSelectedDate"
          :campaignTimezone="campaignTimezone"
        />
      </div>
    </div>
  </b-modal>
</template>
<script>
import PerfCreatives from "./PerfCreatives.vue";
import PerfOSAndBrowser from "./PerfOSAndBrowser.vue";
import PerfHistory from "./PerfHistory.vue";
import PerformanceTable from "./PerformanceTable.vue";
import PerfNotifications from "./PerfNotifications.vue";
import PerfNote from "./PerfNote.vue";
import ActivityLog from "../../views/ActivityLog.vue";
import Audiences from "../../views/Audiences.vue";
import Sites from "../../views/Sites.vue";
import CampaignSiteBlacklist from "../../views/campaignSiteBlacklist.vue";
import numeral from "numeral";
import moment from "moment-timezone";
import { AVAILABLE_TIMEZONES, datePickerRanges } from "../../utils/values.js";

export default {
  name: "PerfModal",
  props: [
    "entityType",
    "entity",
    "selectedDate",
    "perfRangeOptions",
    "timezone",
    "notifications",
  ],
  data: function () {
    return {
      numeral: numeral,
      moment: moment,
      tab: null,
      range: [],
      availableTimezones: AVAILABLE_TIMEZONES,
      campaignTimezone: this.timezone,
      modalSelectedDate: {},
      datePickerRanges: datePickerRanges,
      campaignRangeOptions: this.perfRangeOptions,
      labels: null,
      chart: null,
      totalItems: 0,
      graphData: {},
      history: [],
      totals: null,
      hasNote: false,
    };
  },
  mounted() {
    this.modalSelectedDate = Object.values(this.selectedDate).forEach((date) =>
      date.tz(this.campaignTimezone, true)
    );
    this.$eventHub.$on(
      "syncNoteIcons",
      (data) => (this.hasNote = data.hasNote)
    );
    this.$root.$on("bv::modal::hide", (_bvEvent, _modalId) => {
      if (this.chart) this.chart.destroy();
    });
    if (!localStorage.getItem("legends")) {
      localStorage.setItem("legends", JSON.stringify({}));
    }
  },
  watch: {
    entity: function (_val) {
      this.tab = this.entity.platform === "taboola" ? "sites" : "history";
      this.hasNote = this.entity.has_note;

      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    },
    range: function (n, _o) {
      this.modalSelectedDate.start = moment(n[0], "DD/MM/YYYY").startOf("day");
      this.modalSelectedDate.end = moment(n[1], "DD/MM/YYYY").endOf("day");
      this.loadAndSpreadHistoryData();
    },
    campaignTimezone(n) {
      moment.tz.setDefault(n);

      let start = moment(this.modalSelectedDate.start, "DD/MM/YYYY")
        .tz(n, true)
        .startOf("day");
      let end = moment(this.modalSelectedDate.end, "DD/MM/YYYY")
        .tz(n, true)
        .endOf("day");
      this.$set(this.modalSelectedDate, "start", start);
      this.$set(this.modalSelectedDate, "end", end);

      this.$set(this.campaignRangeOptions, "ranges", this.datePickerRanges());
      this.$set(this.campaignRangeOptions, "startDate", start);
      this.$set(this.campaignRangeOptions, "endDate", end);

      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      this.loadAndSpreadHistoryData();
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  },
  computed: {
    headerName() {
      const { entity, entityType } = this;
      return `${entityType === "adset" ? "adset " : ""}${
        entity.display_name || entity.name
      }`;
    },
    baseUrl() {
      if (!this.entity) return;

      let base = `${this.host}/campaigns/`;
      if (this.entityType == "adset")
        base += `${this.entity.campaign_id}/adsets/`;

      return base + this.entity.id;
    },
    hasMonitizationPresets() {
      return this.entity && (this.entity.monetization_presets || []).length > 1;
    },
    // getPerf: function() {
    //   return this.labels.filter((label) => {
    //     console.log(moment.tz(label, "MMM dd YYYY HH:mm", "America/Los_Angeles"));
    //     return moment.tz(label, "America/Los_Angeles").isBefore();
    //   })
    // }
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    show() {
      this.perfRangeOptions.startDate = this.selectedDate.start;
      this.perfRangeOptions.endDate = this.selectedDate.end;
      this.modalSelectedDate = Object.assign({}, this.selectedDate);
      this.campaignTimezone = this.timezone;
      this.$refs.perfModal.show();
      this.loadAndSpreadHistoryData();
    },
    loadAndSpreadHistoryData() {
      this.history = [];
      this.totals = null;
      this.graphData = {};

      this.$http
        .get(`${this.baseUrl}/history`, {
          params: {
            start: this.modalSelectedDate.start,
            end: this.modalSelectedDate.end,
            timezone: this.campaignTimezone,
          },
        })
        .then((res) => {
          this.history = res.data.history;
          this.totals = res.data.totals;

          this.spreadGraphData();
          this.drawGraph();
        });
    },
    hex2rgba(hex, alpha = 1) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    },
    spreadGraphData() {
      let graphData = {
        label: [],
        revenue: [],
        clicks: [],
        user_value: [],
        cpa: [],
        cpc: [],
        ctr: [],
        profit: [],
        spend: [],
        impressions: [],
        rpm: [],
        adx: [],
        cpm: [],
      };

      this.history.forEach((item) => {
        if (!item["campaign_id"]) {
          graphData.label = [item["label"], ...graphData.label];
          graphData.revenue = [item["revenue"], ...graphData.revenue];
          graphData.clicks = [item["clicks"], ...graphData.clicks];
          graphData.user_value = [item["user_value"], ...graphData.user_value];
          graphData.cpa = [item["cpa"], ...graphData.cpa];
          graphData.cpc = [item["cpc"], ...graphData.cpc];
          graphData.ctr = [item["ctr"], ...graphData.ctr];
          graphData.profit = [item["profit"], ...graphData.profit];
          graphData.spend = [item["spend"], ...graphData.spend];
          graphData.impressions = [
            item["impressions"],
            ...graphData.impressions,
          ];
          graphData.rpm = [item["rpm"], ...graphData.rpm];
          graphData.adx = [item["adx"], ...graphData.adx];
          graphData.cpm = [item["cpm"], ...graphData.cpm];
        }
      });

      this.graphData = graphData;
    },
    getAnnotations(eventName) {
      let delta = -1;
      let bids = this.history
        .slice()
        .reverse()
        .map((h, idx) => {
          if (h.campaign_id) delta++;

          return h.campaign_id && (h.event_name || "").indexOf(eventName) > -1
            ? idx - delta
            : null;
        })
        .filter((h) => {
          return h;
        });

      return bids;
    },
    drawGraph() {
      var ctx = document.getElementById("chart").getContext("2d");

      let colors = [
        "#0a10f5",
        "#bd262c",
        "#dfff00",
        "#0d0902",
        "#dd0dee",
        "#FF0000",
        "#41924B",
        "#0aff8a",
        "#ff720a",
        "#2c0560",
        "#F4D03F",
        "#ffd8fa",
      ];

      let datasets = [
        {
          backgroundColor: this.hex2rgba(colors[0], 0),
          borderColor: this.hex2rgba(colors[0], 1),
          data: this.graphData.revenue,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "Revenue",
        },
        {
          backgroundColor: this.hex2rgba(colors[1], 0),
          borderColor: this.hex2rgba(colors[1], 1),
          data: this.graphData.clicks,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "Clicks",
        },
        {
          backgroundColor: this.hex2rgba(colors[2], 0),
          borderColor: this.hex2rgba(colors[2], 1),
          data: this.graphData.user_value,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "User Value",
        },
        {
          backgroundColor: this.hex2rgba(colors[3], 0),
          borderColor: this.hex2rgba(colors[3], 1),
          data: this.graphData.cpc,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "CPC",
        },
        {
          backgroundColor: this.hex2rgba(colors[4], 0),
          borderColor: this.hex2rgba(colors[4], 1),
          data: this.graphData.profit,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "Profit",
        },
        {
          backgroundColor: this.hex2rgba(colors[5], 0),
          borderColor: this.hex2rgba(colors[5], 1),
          data: this.graphData.spend,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "Spend",
        },
        {
          backgroundColor: this.hex2rgba(colors[6], 0),
          borderColor: this.hex2rgba(colors[6], 1),
          data: this.graphData.impressions,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "Impressions",
        },
        {
          backgroundColor: this.hex2rgba(colors[7], 0),
          borderColor: this.hex2rgba(colors[7], 1),
          data: this.graphData.cpm,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "CPM",
        },
        {
          backgroundColor: this.hex2rgba(colors[8], 0),
          borderColor: this.hex2rgba(colors[8], 1),
          data: this.graphData.rpm,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "RPM",
        },
        {
          backgroundColor: this.hex2rgba(colors[9], 0),
          borderColor: this.hex2rgba(colors[9], 1),
          data: this.graphData.cpa,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "CPA",
        },
        {
          backgroundColor: this.hex2rgba(colors[10], 0),
          borderColor: this.hex2rgba(colors[10], 1),
          data: this.graphData.adx,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "adx&ob",
        },
        {
          backgroundColor: this.hex2rgba(colors[11], 0),
          borderColor: this.hex2rgba(colors[11], 1),
          data: this.graphData.ctr,
          pointBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 2,
          hidden: true,
          label: "CTR",
        },
      ];

      const legendObject = JSON.parse(localStorage.getItem("legends"));
      datasets.forEach((set) => {
        set.data = set.data.reverse();
        if (typeof legendObject[set.label] !== "undefined") {
          set.hidden = legendObject[set.label];
        }
      });

      let bids = this.getAnnotations("max_bid");
      let budgets = this.getAnnotations("daily_budget");

      const originalLegendClickHandler = Chart.defaults.global.legend.onClick;
      Chart.defaults.global.legend.onClick = function (e, legendItem) {
        const legend = legendItem.text;
        const legendObject = JSON.parse(localStorage.getItem("legends"));
        legendObject[legend] = !legendItem.hidden;
        localStorage.setItem("legends", JSON.stringify(legendObject));
        originalLegendClickHandler.call(this, e, legendItem);
      };

      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.graphData.label.reverse(),
          datasets: datasets,
        },
        bidsChangedAt: bids,
        budgetChangedAt: budgets,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                stacked: false,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
  },
  components: {
    PerfCreatives,
    PerfOSAndBrowser,
    PerfHistory,
    PerformanceTable,
    PerfNotifications,
    PerfNote,
    ActivityLog,
    Audiences,
    Sites,
    CampaignSiteBlacklist,
  },
};
</script>
<style>
#perfModal___BV_modal_outer_ {
  z-index: 9999999 !important;
}

header.modal-header {
  flex-direction: row;
  word-wrap: break-word;
}

#perfModal {
  font-size: 12px;
}

.show-calendar {
  z-index: 10000000 !important;
}

@media only screen and (max-width: 576px) {
  .show-calendar {
    overflow-y: scroll;
    height: 500px;
  }
}

.fa-sticky-note {
  background-color: white;
}

@media only screen and (max-width: 768px) {
  header.modal-header {
    flex-direction: column-reverse;
  }

  div.modal-titles {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  div.modal-dialog {
    max-width: 90%;
  }
}

.modal-table {
  overflow-x: scroll;
}
</style>
