import numeral  from 'numeral';
import moment   from 'moment-timezone';

export default {
  methods: {
    isCurrentHour(time) {
      let formatted_label_hour   = moment(time, 'MMM-DD-YYYY hh:mm').format('YYYY-MM-DD HH:mm');
      let formatted_current_hour = moment().tz(this.campaignTimezone).startOf('hour').format('YYYY-MM-DD HH:mm');
      return formatted_label_hour === formatted_current_hour;
    },
    formattedFloat(item, decimals = 1) {
      let format = '0,0.'
      for (let i = 0; i < decimals; i++) format += '0'
      return numeral(item || 0).format(format)
    },
    formattedInt(item) {
      return numeral(item || 0).format('0,0')
    },
    formattedTime(item) {
      return numeral(item).format("00:00:00")
    },
    presentBid(bid) {
      if (!bid) return ''

      let format = "$0.000"
      if (this.entityType === "campaign") {
        delete bid.campaign;
        let bids = Object.values(bid).map(v => numeral(v).format(format));

        return (bids && !(bids === numeral(0).format(format) || bids.length === 0)) ? `(${bids.join(", ")})` : "";
      }
      return numeral(bid).format(format);
    },
    presentDailyBudget(budget) {
      if (!budget) return ''

      let format = "$0,0.0"
      if (this.entityType === "campaign") {
        let campaign_b = budget["campaign"];
        delete budget.campaign;
        let adsets_b = Object.values(budget);

        campaign_b = campaign_b ? numeral(campaign_b || 0).format("$0,0.0") : "";
        adsets_b = adsets_b ? `(${adsets_b.map(v => numeral(v).format(format)).join(", ")})` : "";
        return campaign_b + adsets_b;
      }
      return budget ? numeral(budget || 0).format("$0,0.0") : "";
    },
    activityLogPrint(item) {
      let log = `${item["user"] ? item["user"]["first_name"] : 'N/A'} ${item["user"] ? item["user"]["last_name"] : ''}`;
      if (item["changed_field"] === "learning_phase") {
        log = `Campaign <b>${item["field_value"]}</b> learning phase`;
      } else if (item["event"]) {
        let ad = item["ad"] ? ` for the creative <b>${item["ad"]["name"]}</b>` : "";
        let values = (item["field_value"] || "")
          .split(", ")
          .map(v => (v === "" ? "{null}" : `<b>${v}</b>`))
          .join(" and ");
        log += ` ${item["event"]} ${item["changed_field"]
          .split(", ")
          .map(v => `<b>${v}</b>`)
          .join(" and ")}${ad} to ${values}`;
      } else {
        let event = item["event_value"] ? ` to ${item["event_value"]}` : "";
        log += ` ${item["event_name"]}${event}`;
      }
      return log;
    }
  }
}
