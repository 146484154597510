<template>
  <table class="text-center table table-bordered table-responsive-md">
    <thead>
      <tr>
        <td>Date</td>
        <td>Reach</td>
        <td>Clicks</td>
        <td>Visits</td>
        <td>Sessions</td>
        <td>Bounce %</td>
        <td>User Value</td>
        <td>Est. Visit Value</td>
        <td>Est. Session Value</td>
        <td>CPC</td>
        <td>CPM</td>
        <td>Revenue</td>
        <td>Est. Revenue</td>
        <td>Spend</td>
        <td>Profit</td>
        <td>Est. Profit</td>
        <td>Margin</td>
        <td>Est. Margin</td>
        <td>ROI</td>
        <td>RPM</td>
        <td>Est. RPM</td>
        <td>APV</td>
        <td>APS</td>
        <td>CTR</td>
        <td v-if="entity['platform'] === 'taboola'">vCTR</td>
        <td>Results</td>
        <td>Result Rate</td>
        <td>Items</td>
        <td>Avg Session</td>
        <td>Bid</td>
        <td>Daily Budget</td>
      </tr>
    </thead>
    <tbody>
      <tr class="total-row" v-if="totals">
        <td>Total</td>
        <td>{{ formattedInt(totals["source_impressions"]) }}</td>
        <td>{{ formattedInt(totals["clicks"]) }}</td>
        <td>{{ formattedInt(totals["lands"]) }}</td>
        <td>{{ formattedInt(totals["visits"]) }}</td>
        <td>
          {{ formattedInt(totals["bounce"] <= 0 ? 0 : totals["bounce"]) }}%
        </td>
        <td>{{ formattedFloat(totals["user_value"], 3) }}</td>
        <td>{{ formattedFloat(totals["est_visit_value"], 3) }}</td>
        <td>
          {{ formattedFloat(totals["est_session_value"], 3) }}
        </td>
        <td>{{ formattedFloat(totals["cpc"], 3) }}</td>
        <td>
          {{
            formattedFloat(
              (totals["spend"] / totals["source_impressions"]) * 1000,
              3
            )
          }}
        </td>
        <td>{{ formattedFloat(totals["revenue"]) }}</td>
        <td>{{ formattedFloat(totals["est_revenue"], 3) }}</td>
        <td>{{ formattedFloat(totals["spend"], 3) }}</td>
        <td>{{ formattedFloat(totals["profit"], 3) }}</td>
        <td>{{ formattedFloat(totals["est_profit_real_spend"], 3) }}</td>
        <td>{{ formattedFloat(totals["margin"], 1) }}%</td>
        <td>{{ formattedFloat(totals["est_margin"], 1) }}%</td>
        <td>{{ formattedFloat(totals["roi"], 3) }}%</td>
        <td>{{ formattedFloat(totals["rpm"], 3) }}</td>
        <td>{{ formattedFloat(totals["est_rpm"], 3) }}</td>
        <td>
          {{
            formattedFloat(
              totals["lands"] > 0 ? totals["impressions"] / totals["lands"] : 0,
              1
            )
          }}
        </td>
        <td>
          {{
            formattedFloat(
              totals["visits"] > 0
                ? totals["impressions"] / totals["visits"]
                : 0,
              1
            )
          }}
        </td>
        <td>{{ formattedFloat(totals["ctr"], 1) }}%</td>
        <td v-if="entity['platform'] === 'taboola'">
          {{ formattedFloat(totals["vctr"], 1) }}%
        </td>
        <td>{{ formattedInt(totals["results"]) }}</td>
        <td>{{ formattedFloat(totals["result_rate"], 2) }}%</td>
        <td>{{ formattedFloat(totals["units"], 1) }}</td>
        <td>{{ formattedTime(totals["avg_session"]) }}</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <template v-for="(item, index) in history">
        <tr v-if="item['event'] || item['event_name']" :key="index">
          <td>
            {{ item["label"] }}
          </td>
          <td colspan="26" v-html="activityLogPrint(item)"></td>
        </tr>
        <tr v-else :key="index">
          <td>
            <i
              v-if="isCurrentHour(item['label'])"
              class="fas fa-arrow-right"
            ></i>
            {{ item["label"] }}
          </td>
          <td>{{ formattedInt(item["source_impressions"]) }}</td>
          <td>{{ formattedInt(item["clicks"]) }}</td>
          <td>{{ formattedInt(item["lands"]) }}</td>
          <td>{{ formattedInt(item["visits"]) }}</td>
          <td>{{ formattedInt(item["bounce"] <= 0 ? 0 : item["bounce"]) }}%</td>
          <td>{{ formattedFloat(item["user_value"], 3) }}</td>
          <td>{{ formattedFloat(item["est_visit_value"], 3) }}</td>
          <td>
            {{ formattedFloat(item["est_session_value"], 3) }}
          </td>
          <td>{{ formattedFloat(item["cpc"], 3) }}</td>
          <td>{{ formattedFloat(item["cpm"], 3) }}</td>
          <td>{{ formattedFloat(item["revenue"], 3) }}</td>
          <td>{{ formattedFloat(item["est_revenue"]) }}</td>
          <td>{{ formattedFloat(item["spend"], 3) }}</td>
          <td>{{ formattedFloat(item["profit"], 3) }}</td>
          <td>{{ formattedFloat(item["est_profit_real_spend"]) }}</td>
          <td>{{ formattedFloat(item["margin"], 1) }}%</td>
          <td>{{ formattedFloat(item["est_margin"], 1) }}%</td>
          <td>{{ formattedFloat(item["roi"], 3) }}%</td>
          <td>{{ formattedFloat(item["rpm"], 3) }}</td>
          <td>{{ formattedFloat(item["est_rpm"], 3) }}</td>
          <td>{{ formattedFloat(item["imps_per_lands"], 1) }}</td>
          <td>{{ formattedFloat(item["imps_per_visit"], 1) }}</td>
          <td>{{ formattedFloat(item["ctr"], 1) }}%</td>
          <td v-if="entity['platform'] === 'taboola'">
            {{ formattedFloat(item["vctr"], 1) }}%
          </td>
          <td>{{ formattedInt(item["results"]) }}</td>
          <td>{{ formattedFloat(item["result_rate"], 2) }}%</td>
          <td>{{ formattedFloat(item["units"], 1) }}</td>
          <td>-</td>
          <td>{{ presentBid(item["bid"]) }}</td>
          <td>{{ presentDailyBudget(item["daily_budget"]) }}</td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import prefMethods from "./PrefMethods.js";

export default {
  name: "PerfHistory",
  props: [
    "entityType",
    "entity",
    "modalSelectedDate",
    "campaignTimezone",
    "totals",
    "history",
  ],
  mixins: [prefMethods],
};
</script>
<style scoped>
* {
  font-size: 12px;
}

.total-row {
  background: #ccc;
}
</style>
