<template>
    <vs-card>
      <div slot="header"><h5>Table Columns</h5></div>

      <b-form-group>
        <b-button @click="selectAll" size="sm" pill variant="success">Select All</b-button>
        <b-button class="ml-4" @click="deselectAll" size="sm" pill variant="outline-secondary">Deselect All</b-button>
      </b-form-group>

      <draggable @change="onOrderChange" v-model="columns" class="metrics-list" @start="drag=true" @end="drag=false">
        <div class="metrics" :key="key" v-for="(metric, key) in columns">
          <b-checkbox v-model="selectedColumns" :value="metric.value">{{ metric.text }}</b-checkbox>
        </div>
      </draggable>

      <b-form-group>
        <b-button @click="save" pill variant="primary">Save</b-button>
        <b-button class="ml-4" @click="loadFromStorage(true)" pill variant="outline-primary">Cancel</b-button>
      </b-form-group>
    </vs-card>
</template>
<script>
import { STORAGE, COLUMNS } from '../../utils/settings/columns.js';
import draggable from 'vuedraggable'
export default {
  name: 'ColumnsPicker',
  props: ['reloadTable', 'closeWidget'],
  data() {
    return {
      selectedColumns: [],
      defaultCol: 'clicks',
      columns: Object.entries(COLUMNS.getHeaders).map(o => { return { text: o[1], value: o[0] }}),
      newColumnsOrder: []
    }
  },
  watch: {
    selectedColumns: function(n, o) {
      if (!this.validateMetricsPresence(n)) {
        alert('must check at least one mandatory column')
        this.$nextTick(() => {this.selectedColumns = o})
        return;
      }
    }
  },
  components: {
    draggable
  },
  mounted() {
    this.loadFromStorage(false);
    this.newColumnsOrder = this.columns.map(column => column.value);
  },
  methods: {
    selectAll() {
      let allCols = this.columns.map(o => o.value)
      this.selectedColumns = allCols;
    },
    deselectAll() {
      this.selectedColumns = [this.defaultCol];
    },
    validateMetricsPresence(cols = []) {
      return cols.some(COLUMNS.isColMetricsAgg);
    },
    save() {
      STORAGE.set(this.selectedColumns, this.newColumnsOrder);
      this.reloadTable();
      this.closeWidget();
    },
    loadFromStorage(closeModal) {
      let preloadedValues = STORAGE.get();
      if (!preloadedValues) this.selectAll()
      this.selectedColumns = preloadedValues
      if(closeModal) this.closeWidget();
    },
    onOrderChange() {
      this.newColumnsOrder = this.columns.map(column => column.value)
    }
  }
}
</script>

<style>
.colums-setting-switches div.custom-switch {
  margin-right: 1rem;
  margin-top: 1rem;
}
.metrics-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
}
.metrics-list > div {
  margin: 0 8px;
}
.metrics-list label {
  line-height: 24px;
  cursor: move;
}
.metrics-list label::before,
.metrics-list label::after {
  cursor: pointer;
}
</style>