<template>
  <div>
    <div class="p-3">
      Preset:
      <select v-model="selectedPreset" class="ml-1">
        <option
          v-for="presetName in presetNames"
          :value="presetName"
          :key="presetName"
        >
          {{ presetName }}
        </option>
      </select>
    </div>
    <div>
      <table class="text-center table table-bordered table-responsive-md">
        <thead>
          <tr>
            <td>Date</td>
            <td>Visits</td>
            <td>Sessions</td>
            <td>Revenue</td>
            <td>Est. Revenue</td>
            <td>CPM</td>
            <td>Visit Value</td>
            <td>Est. VV</td>
            <td>Est. SV</td>
            <td>RPM</td>
            <td>Est. RPM</td>
            <td>APV</td>
            <td>APS</td>
            <td>Items</td>
          </tr>
        </thead>
        <tbody>
          <tr class="totals-row">
            <td>Total</td>
            <td>{{ formattedInt(selectedTotals["lands"]) }}</td>
            <td>{{ formattedInt(selectedTotals["visits"]) }}</td>
            <td>{{ formattedFloat(selectedTotals["revenue"], 3) }}</td>
            <td>{{ formattedFloat(selectedTotals["est_revenue"], 3) }}</td>
            <td>{{ formattedFloat(selectedTotals["cpm"], 3) }}</td>
            <td>{{ formattedFloat(selectedTotals["visit_value"], 3) }}</td>
            <td>{{ formattedFloat(selectedTotals["est_visit_value"], 3) }}</td>
            <td>
              {{ formattedFloat(selectedTotals["est_session_value"], 3) }}
            </td>
            <td>{{ formattedFloat(selectedTotals["rpm"], 3) }}</td>
            <td>{{ formattedFloat(selectedTotals["est_rpm"], 3) }}</td>
            <td>
              {{
                formattedFloat(
                  selectedTotals["lands"] > 0
                    ? selectedTotals["impressions"] / selectedTotals["lands"]
                    : 0,
                  1
                )
              }}
            </td>
            <td>
              {{
                formattedFloat(
                  selectedTotals["visits"] > 0
                    ? selectedTotals["impressions"] / selectedTotals["visits"]
                    : 0,
                  1
                )
              }}
            </td>
            <td>{{ formattedFloat(selectedTotals["units"]) }}</td>
          </tr>
          <template v-for="(preset, i) in selectedPresets">
            <tr v-if="preset['user']" :key="`preset-${i}`">
              <td>{{ preset["label"] }}</td>
              <td colspan="12" v-html="activityLogPrint(preset)"></td>
            </tr>
            <tr v-else :key="`preset-${i}`">
              <td>
                <i
                  v-if="isCurrentHour(preset['label'])"
                  class="fas fa-arrow-right"
                ></i
                >{{ preset["label"] }}
              </td>
              <td>{{ formattedInt(preset["lands"]) }}</td>
              <td>{{ formattedInt(preset["visits"]) }}</td>
              <td>{{ formattedFloat(preset["revenue"], 3) }}</td>
              <td>{{ formattedFloat(preset["est_revenue"], 3) }}</td>
              <td>{{ formattedFloat(preset["visit_value"], 3) }}</td>
              <td>{{ formattedFloat(preset["est_visit_value"], 3) }}</td>
              <td>
                {{ formattedFloat(selectedTotals["est_session_value"], 3) }}
              </td>
              <td>{{ formattedFloat(preset["rpm"], 3) }}</td>
              <td>{{ formattedFloat(preset["est_rpm"], 3) }}</td>
              <td>{{ formattedFloat(preset["imps_per_lands"]) }}</td>
              <td>
                {{
                  formattedFloat(
                    preset["visits"] > 0
                      ? preset["impressions"] / preset["visits"]
                      : 0,
                    1
                  )
                }}
              </td>
              <td>{{ formattedFloat(preset["units"]) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import prefMethods from "./PrefMethods.js";

export default {
  name: "PerformanceTable",
  props: [
    "entityType",
    "entity",
    "baseUrl",
    "modalSelectedDate",
    "campaignTimezone",
  ],
  mixins: [prefMethods],
  data() {
    return {
      totals: {},
      presetNames: [],
      performance: {},
      selectedPreset: null,
    };
  },
  computed: {
    selectedTotals: function () {
      return this.totals[this.selectedPreset || 0] || {};
    },
    selectedPresets: function () {
      return this.performance[this.selectedPreset || 0] || [];
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.totals = {};
      this.performance = {};
      this.presetNames = [];

      this.$http
        .get(`${this.baseUrl}/performance`, {
          params: {
            start: this.modalSelectedDate.start,
            end: this.modalSelectedDate.end,
            timezone: this.campaignTimezone,
          },
        })
        .then((res) => {
          this.totals = res.data.totals;
          this.performance = res.data.performance;
          this.presetNames = Object.keys(res.data.performance).filter(
            (k) => k !== "no_preset"
          );
          if (!this.selectedPreset) this.selectedPreset = this.presetNames[0];
        });
    },
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
}

.totals-row {
  background: #ccc;
}
</style>
