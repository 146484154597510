<template>
  <tr :key="campaign.id" class="basic-color campaign-row">
    <td class="chart-td">
      <span v-if="campaign.id > 0" :class="`campaign-status ${campaign.status}`" @click="openStatusChange([campaign], campaign.status === 'running' ? 'paused' : 'running')"><i :class="`fas ${statusIcons(campaign.status)}`"></i></span>
    </td>
    <td class="chart-td" @click="openPerfModal(campaign)">
      <canvas v-if="tiny_perfs[campaign.id] && !isTinyPerfEmpty(campaign.id)" :id="`sparkline-${campaign.id}`" width="70" height="30"></canvas>
      <span v-else class="btn badge badge-secondary">No Data</span>
    </td>
    <td class="th2 text-left basic-color campaign-row">
      <div class="campaign-name" v-if="campaign">
        <div>
          <b-tooltip disabled :id="`${campaign.id}-tooltip-name`" :target="`${campaign.id}-name`" :title="(campaign.display_name || campaign.name || '').replace(/_/g, ' ')"></b-tooltip>
          <div :id="`${campaign.id}-name`" class="campaign-name-title mr-2">
            <div>
              <div @click="toggleAdsets(campaign)" :class="`campaign-name-title-text ${showAdsets === campaign.id ? '' : 'ellipsis'}`">{{ campaign.display_name || campaign.name || ''}}
                <span v-if="notifications[campaign.id]" class="ml-2 badge badge-danger badge-pill">{{ notifications[campaign.id] }}</span>
                <span v-if="campaign.has_note" class="ml-2 badge badge-warning badge-pill"><i class="far fa-sticky-note"></i></span>
                <span v-if="campaign.is_in_learning_phase" class="ml-2 badge badge-info badge-pill"><i class="fas fa-balance-scale-right"></i></span>
              </div>
              <div class="hash-code">ID: {{ campaign.id }}. Created: {{ campaign.created_ago }} ago. <span :class="moment(new Date()).diff(campaign.user_changed_at, 'minutes') <= 30 ? 'text-bold' : ''">Last changed: {{ campaign.last_audit_ago ? `${campaign.last_audit_ago} ago` : 'never' }}</span>. Last Cost: {{ campaign.last_cost_ago ? `${campaign.last_cost_ago} ago` : 'never' }}</div>
            </div>
          </div>
        </div>
        <div class="campaign-name-icons" style="display: flex; align-items: center">
          <i v-if="campaign.complement" class="fal fa-snooze"></i>
          <i v-if="campaign.start_time && campaign.status != 'running'" class="text-danger far fa-clock"></i>    
          <span v-if="moment(new Date()).diff(campaign.created_at, 'hours') <= 72" class="text-light badge badge-primary mx-2">NEW</span>
          <i v-b-tooltip.hover v-if="campaign.bid_strategy && campaign.bid_strategy === 'LOWEST_COST_WITH_BID_CAP'" title="Bid Cap" class="m-2 fas fa-hat-cowboy"></i>
          <country-flag v-if="campaign.countries && isSingleCountry(campaign.countries)" :country='campaign.countries[0]' size='small'/>
          <i v-else-if="campaign.countries" class="far fa-globe-americas"></i>
          <i v-b-tooltip.hover v-if="campaign.devices && campaign.devices.length > 0" :title='getTitleForDevice(campaign.devices)' :class="`devices ${getClassForDevice(campaign.devices)}`"></i>
          <i v-if="campaign.platform == 'push'" class="fal fa-bells"></i>
          <i v-else-if="campaign.platform == 'tiktok'" class="fas fa-music-alt"></i>
          <i v-else :class="`fab fa-${campaign.platform}${campaign.platform == 'facebook' ? '-f' : (campaign.platform == 'snapchat' ? '-ghost' : '')}`"></i>
          <b-dropdown id="dropdown-dropright" dropright text=" " size="md" variant="link" toggle-class="fal fa-ellipsis-v text-decoration-none" no-caret>
            <b-dropdown-item :to="{ name: 'EditCampaign', params: { id: campaign.id } }">Edit</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'DuplicateCampaign', params: { id: campaign.id } }">Duplicate</b-dropdown-item>
            <b-dropdown-item target="_blank" :href="externalLink(campaign)">Open On Source</b-dropdown-item>
            <b-dropdown-item target="_blank" :href="wpLink(campaign)">Edit On WP</b-dropdown-item>
            <b-dropdown-item target="_blank" :href="campaign.article_url">Open URL</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </td>
    <td>
      <div v-if="((budgets[campaign.id] || {}).daily_budget !== undefined || (edits[campaign.id] || {}).isEditingDailyBudget) && (platformOptions(campaign.platform).daily_budget || {}).campaign" class="input-group">
        <div class="input-group-prepend"><span class="input-group-text">$</span></div>
        <input type="text" :disabled="!(edits[campaign.id] || {}).isEditingDailyBudget || campaign.status != 'running'" min="0.00" class="form-control" v-model="budgets[campaign.id].daily_budget" v-on:keyup.enter="saveCampaignDailyBudget(campaign)"/>
        <i class="edit-btn fas fa-pencil text-secondary" v-if="budgets[campaign.id] && !(edits[campaign.id] || {}).isEditingDailyBudget && campaign.status == 'running'" @click="editCampaignDailyBudget(campaign)"></i>
        <template v-else-if="edits[campaign.id] && edits[campaign.id].isEditingDailyBudget">
          <i class="edit-btn fas fa-check text-secondary" @click="saveCampaignDailyBudget(campaign)"></i>
          <i class="edit-btn fas fa-history text-secondary" @click="revertCampaignDailyBudget(campaign)"></i>
        </template>
        <b-alert @dismissed="dismissCountDown=0" :show="dismissCountDown" @dismiss-count-down="countDownChanged" :variant="alertType" class="alert-fixed" dismissible>
          {{ alertError }}
        </b-alert>
      </div>
      <div class="budget_type">
          {{ campaign.metadata.budget_type == 'lifetime' ? 'lifetime' : 'daily' }}
      </div>
    </td>
    <template v-for="col in selectedColumnsData">
      <td :key="col.name" :class="col.cssClass ? col.cssClass(campaign) : ''">
        <template v-if="col.name == 'units'">
          <UnitsTooltip :campaign_id="campaign.id" :timezone="timezone" :start="selectedDate.start" :end="selectedDate.end" :content="col.format(campaign)" />
        </template>

        <template v-else-if="col.name == 'rpm'">
          <span @click="loadRpms(campaign.id)" :id="`popover-rpm-${campaign.id}`" variant="primary" class="revenu-btn">{{ col.format(campaign) }}
          </span>
          <b-popover :target="`popover-rpm-${campaign.id}`" triggers="hover focus" custom-class="popover-custom-body">
            <table>
              <tbody>
              <tr :key="key + campaign.id" v-for="(campaign, key) in rpms">
                <td>{{ key }}</td>
                <td>{{ numeral(campaign).format('$0,0.000') }}</td>
              </tr>
              </tbody>
            </table>
          </b-popover>
        </template>

        <template v-else-if="col.name == 'revenue'">
          <span @click="loadRevenues(campaign.id)" :id="`popover-${campaign.id}`" variant="primary" class="revenu-btn">{{ col.format(campaign) }}</span>
          <b-popover :target="`popover-${campaign.id}`" triggers="hover focus" custom-class="popover-custom-body">
            <table>
              <tbody>
              <tr :key="key + campaign.id" v-for="(campaign, key) in revenues">
                <td>{{ key }}</td>
                <td>{{ numeral(campaign).format('$0,0.000') }}</td>
              </tr>
              </tbody>
            </table>
          </b-popover>
        </template>

        <template v-else-if="col.name == 'quality'">
          {{ col.format(campaign) }}<span v-if="campaign.has_low_quality" class="ml-2 badge badge-danger badge-pill"><i class="fas fa-exclamation"></i></span>
        </template>

        <template v-else-if="col.name == 'negative_sentiment'">
          <span :class="col.cssClass ? col.cssClass(campaign) : ''"><i class="fal fa-exclamation-circle" v-if="campaign.negative_sentiment > 10"></i> {{ col.format(campaign) }}</span>
        </template>

        <span v-else>{{ col.format(campaign) }}</span>
        <template v-if="col.customElement">
          <span :class="col.customElement.cssClass ? col.customElement.cssClass(campaign) : '' "> {{ col.customElement.format(campaign) }}</span>
        </template>
      </td>
    </template>
    <PerfModal ref="perfModal" entityType="campaign" :entity="currentCampaign" :selectedDate="selectedDate" :perfRangeOptions="perfRangeOptions" :timezone="timezone" :notifications="notifications[(currentCampaign || {}).id]"/>
  </tr>
</template>
<script>
  import moment from 'moment-timezone';
  import CountryFlag from 'vue-country-flag';
  import numeral from 'numeral'
  import PerfModal from '../perf/PerfModal';
  import { OPTIONS, VALIDATION } from '../../utils/campaign_form.js'
  import UnitsTooltip from '../../components/overview/UnitsTooltip.vue';
  import { ICONS, DEVICES_DISPLAY } from '../../utils/overview.js'
  import { STORAGE } from '../../utils/settings/columns.js'
  import { alog, ilog, elog } from '../../utils/logger/logger.js'
  import { PUBLISHERS } from "../../utils/product_entities.js";

  export default {
    name: "CampaignsRow",
    props: {
      campaign: Object,
      tiny_perfs: Object,
      selectedColumnsData: Array,
      getFilteredParams: Function,
      perfRangeOptions: Object,
      notifications: Object,
    },
    data: function() {
      return {
        rpms: {},
        numeral,
        revenues: {},
        currentCampaign: null,
        publishers: PUBLISHERS,
        lockedCampaign: null,
        showAdsets: null,
        budgets: {},
        edits: {},
        moment,
        dismissSecs: 120,
        dismissCountDown: 0,
        alertType: 'danger',
        alertError: '',
        timezone: localStorage.getItem("tz") || 'UTC',
        selectedDate: {
          start: moment().startOf('day'),
          end: moment().endOf('day')
        },
      };
    },
    mounted() {
      moment.tz.setDefault(this.timezone);
      this.selectedColumns = STORAGE.get();
      this.budgets = {
        [this.campaign.id]: {
          daily_budget: this.campaign.metadata.daily_budget
        }
      };
    },
    computed: {
      localeCodes: function() {
        return this.filter_locale.map(l => l.code).join(',')
      },
      realtime_users_data: function() {
        return this.realtime_users;
      },
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      statusIcons(status) {
        return ICONS.status[status]
      },
      isSingleCountry(countries) {
        return (countries.length == 1 || countries.every(c => c == countries[0])) && countries[0] != 'all'
      },
      toggleAdsets(campaign) {
        this.nameTooltip = null;

        this.showAdsets = ( this.showAdsets === campaign.id ) ? null : campaign.id;
      },

      externalLink(content)
      {
        switch(content.platform) {
          case 'facebook':
            return `https://business.facebook.com/adsmanager/manage/all?act=${content.ad_account}&business_id=487945378071353&selected_campaign_ids=${content.external_id}&root_level=ad_set`;
          case 'snapchat':
            return `https://ads.snapchat.com/${content.ad_account}/campaigns/${content.external_id}`;
          case 'pinterest':
            return `https://ads.pinterest.com/advertiser/${content.ad_account}/reporting/campaigns/?name=${content.name}`;
        }
      },
      wpLink(content)
      {
        if (!content.metadata)
          return;
          
        let article_id = content.metadata.utm_medium;
        let url = content.publisher.replace("www.", "");
        return `https://editors.${url}/wp-admin/post.php?post=${article_id}&action=edit`
      },
      updateRealTimeUsers() {
        if (this.campaigns && this.realTimeUsers) {
          this.campaigns.forEach(camp => {
            if (this.realTimeUsers[camp.id])
              this.$set(camp, 'realtimeUsers', this.realTimeUsers[camp.id]);
          })
        }
        this.totals['realtimeUsers'] = Object.values(this.realTimeUsers || {}).reduce((acc, val) => acc + val, 0)
      },
      getTitleForDevice(devices) {
        return DEVICES_DISPLAY.title(devices)
      },
      getClassForDevice(devices) {
        return DEVICES_DISPLAY.icon(devices)
      },
      saveCampaignDailyBudget(campaign) {
        alog(`Campaign Daily Budget Update (camp ${campaign.id})`, this.budgets[campaign.id].oldDailyBudget, this.budgets[campaign.id].daily_budget)
        if (!VALIDATION.budget(this.budgets[campaign.id].daily_budget, campaign.platform)) return;

        this.$http.put(`${this.host}/campaigns/${campaign.id}/update_external`, {
          attribute: 'daily_budget',
          value: this.budgets[campaign.id].daily_budget
        }).then(res => {
          this.alertError = 'Daily Budget updated successfully'
          this.dismissCountDown = this.dismissSecs;
          this.alertType = 'success';
          this.budgets[campaign.id].oldDailyBudget = this.budgets[campaign.id].daily_budget;
          ilog(`${this.alertError} (camp ${campaign.id})`)
          this.refreshCampaignLastChanged(res.data)
        }).catch(ex => {
          this.alertError = ex.response.data.error
          this.dismissCountDown = this.dismissSecs;
          this.alertType = 'danger';
          this.budgets[campaign.id].oldDailyBudget = this.budgets[campaign.id].daily_budget;
          elog(`${this.alertError} (camp ${campaign.id})`, ex)
        }).finally(() => {
          delete this.edits[campaign.id];
        })

      },
      revertCampaignDailyBudget(campaign) {
        alog(`revert campaign daily budget (camp ${campaign.id})`, this.budgets[campaign.id].daily_budget, this.budgets[campaign.id].oldDailyBudget)
        this.budgets[campaign.id].daily_budget = this.budgets[campaign.id].oldDailyBudget;
        delete this.edits[campaign.id];
        this.$forceUpdate();
      },
      editCampaignDailyBudget(campaign) {
        this.budgets[campaign.id].oldDailyBudget = this.budgets[campaign.id].daily_budget;
        this.edits[campaign.id] = {
          isEditingDailyBudget: true
        };
        this.$forceUpdate();
      },
      refreshCampaignLastChanged(data) {
        this.$emit(this.campaign.last_audit_ago, data["last_audit_ago"])
      },
      openStatusChange(campaigns, newStatus) {
        if (this.working || campaigns.some((c) => !c.status.match(/running|paused|create_error/))) return;

        const errorCampaigns = campaigns.filter((c) => c.status === "create_error");
        if (errorCampaigns.length > 0) {
          for (const campaign of errorCampaigns) {
            this.creationRetry(campaign);
          }
          return;
        }

        if (
          confirm(
            `You are about to ${
              newStatus == "running" ? "activate" : "pause"
            } the following campaigns: ${campaigns.map((c) => c.name).join("\n")}
            \nContinue?`
          )
        ) {
          campaigns.forEach((c) => {
            c.status = "pending";
            alog(`Campaigns status change (camp ${c.id})`, c.status, newStatus);
          });

          const campaignIds = campaigns.map((c) => c.id);
          this.$http
            .put(`${this.host}/campaigns/update_status`, {
              campaign_ids: campaignIds,
              status: newStatus,
            })
            .then((_res) => {
              this.alertType = "info";
              this.alertError = "Campaigns status updated successfully";
              campaigns.forEach((c) => (c.status = newStatus));
              this.showAdsets = null;
              this.nameTooltip = null;
              this.dismissCountDown = this.dismissSecs;
              this.$forceUpdate();
              this.refresh();

              const adsetActivateCampaigns = campaigns.filter(
                (c) => c.status === "running" && c.platform !== "taboola"
              );
              if (adsetActivateCampaigns.length > 0)
                alert(`Make sure to activate the adsets manually for the following campaigns: ${adsetActivateCampaigns.map((c) => c.id)}`);

              ilog(`${this.alertError} (camps ${campaignIds})`);
            })
            .catch((err) => {
              this.alertType = "danger";
              this.alertError = `${
                err.response.data.succeeded.length > 0
                  ? `Campaign status update SUCCEEDED for:\n${err.response.data.succeeded.join(", ")}\n`
                  : ""
              }
              Campaign status update FAILED for:\n${err.response.data.failed.map(
                (c) => `Campaign Id: ${c.campaign_id}\nReason: ${c.error}`
              ).join("\n\n")}`;

              this.dismissCountDown = this.dismissSecs;
              elog(`${this.alertError} (camps ${campaignIds})`, err);
            })
            .finally(() => {
              this.working = false;
            });
        }
      },
      creationRetry(campaign){
        if (!confirm(`Do you really want to retry creating ${campaign.name} ?`))
          return;

        this.working = true;
        this.$http.get(`${this.host}/campaigns/${campaign.id}/retry_create`)
          .then(res => {
            this.alertType        = 'info';
            this.alertError       = 'Campaign sent to retry';
            campaign.status       = res.data.status;
            this.showAdsets       = null;
            this.nameTooltip      = null;
            this.dismissCountDown = this.dismissSecs;
          })
          .catch(err => {
            this.alertError       = `Updating campaign status failed\n${err.response.data.error || err.response.data }`;
            this.alertType        = 'danger';
            campaign.status       = 'create_error';
            this.dismissCountDown = this.dismissSecs;
            elog(`${this.alertError} (camp ${campaign.id})`, err);
          })
          .finally(() => {
            this.working = false
          })
      },
      isTinyPerfEmpty(campaign_id) {
        return this.tiny_perfs[campaign_id].every(val => val === 0)
      },
      
      openPerfModal(campaign) {
        this.currentCampaign = campaign;
        this.$nextTick(() => {
          this.$refs.perfModal.show()
        })
      },
      loadRevenues: function(campaign_id) {
        if (!campaign_id)
          return;

        this.revenues = {};
        this.$http.get(`${this.host}/campaigns/${campaign_id}/revenues`, {
          params: this.getFilteredParams()
        }).then(res => {
          this.revenues = res.data.revenues
        })
      },
      loadRpms: function(campaign_id) {
        if (!campaign_id)
          return;

        this.rpms = {};
        this.$http.get(`${this.host}/campaigns/${campaign_id}/rpms`, {
          params: this.getFilteredParams()
        }).then(res => {
          this.rpms = res.data.rpms
        })
      },
      platformOptions(platform) { return OPTIONS.platform(platform) }
    },
    
  components: {
    PerfModal,
    UnitsTooltip,
    CountryFlag
  }
};
</script>
<style lang="scss" scoped>

.campaign-row  {

  .alert-fixed {
    @apply fixed z-10;

    width: 440px;
    left: 380px;
    top: 68px;
  }
  .chart-td {
    @apply align-middle;
  }

  .input-group {
    input {
      width: 60px;
    }
    input, .input-group-text {
      font-size: 13px;
    }
    .edit-btn {
      @apply cursor-pointer;
    }
  }
  .budget_type { 
    font-size: 10px;
    color: grey;
  }
}

.campaign-name {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .campaign-name-title {
    display: flex;
    align-items: center;

    .campaign-name-title-text {

      font-weight: bold;
      font-size: 14px;
      max-width: 35vw;
      // min-width: 20vw;

      &.ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .hash-code { 
      font-weight: normal;
      font-size: 10px;
      color: grey;
    }
  }

  .campaign-name-icons {
    display: flex;
    align-items: center;
    
    .small-flag {
      margin: -0.9em -1.2em -0.9em -1.2em;
    }

    > i {
      margin: 0 2px;
    }
  }
}

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
