<template>
  <div>
    <div
      v-if="this.adAccountTimezone !== null && !timezonesMatch"
      class="alert alert-warning"
    >
      Results are in {{ adAccountTimezone }} timezone
    </div>

    <div id="ad-toaster-wrapper">
      <b-toaster name="ad-toaster" position="top-center"> </b-toaster>
    </div>

    <table class="text-center table table-bordered table-responsive-md mt-2">
      <thead>
        <tr class="no-top-border">
          <td colspan="4" />
          <td><b>Impressions</b></td>
          <td><b>Clicks</b></td>
          <td><b>Visits</b></td>
          <td><b>Sessions</b></td>
          <td><b>Est. Revenue</b></td>
          <td><b>Spend</b></td>
          <td><b>CPM</b></td>
          <td><b>Est. UV</b></td>
          <td><b>Est. VV</b></td>
          <td><b>CPC</b></td>
          <td><b>Est. Margin</b></td>
          <td><b>CTR</b></td>
          <td><b>Bounce %</b></td>
          <td><b>Visit Bounce %</b></td>
          <td v-if="hasVCTR"><b>vCTR</b></td>
          <td><b>Cost Per Result</b></td>
          <td><b>Results</b></td>
          <td><b>Quality</b></td>
          <td />
          <td />
        </tr>
      </thead>
      <tbody>
        <template v-for="adset in adsets">
          <tr :key="adset.name" class="no-top-border">
            <td colspan="13">
              <h4
                class="adset-title text-left pt-3 mb-0"
                v-if="adsets.length > 1"
              >
                {{ adset.name }}
              </h4>
            </td>
          </tr>
          <tr
            :key="adset.name + '_preview'"
            class="no-top-border"
            v-if="adset.preview_image"
          >
            <td colspan="13" class="img-col thumbnail adset-title text-left">
              <img :src="adset.preview_image" />
              Preview
            </td>
          </tr>
          <template v-for="cre in adset.creatives">
            <tr :key="cre.ad_name" :class="{ disabled: !cre.active }">
              <td class="img-col thumbnail">
                <video
                  v-if="cre.image.includes('.mp4')"
                  :src="cre.image"
                  muted
                />
                <img v-else :src="cre.image" />
              </td>
              <td colspan="3" class="ad-name-cell text-left">
                <b class="ad-name"
                  ><a
                    target="_blank"
                    rel="noopener noreferrer"
                    :href="cre.external_url ? cre.external_url : false"
                    >{{ cre.ad_name }}</a
                  ></b
                >
                <p>{{ cre.caption }}</p>
                <p>
                  <i>{{ cre.text }}</i>
                </p>
              </td>
              <td>{{ numeral(cre.impressions || 0).format("0,0") }}</td>
              <td>{{ numeral(cre.clicks || 0).format("0,0") }}</td>
              <td>{{ numeral(cre.lands || 0).format("0,0") }}</td>
              <td>{{ numeral(cre.visits || 0).format("0,0") }}</td>
              <td>{{ numeral(cre.estimated_revenue).format("0.00") }}</td>
              <td>{{ numeral(cre.spend || 0).format("$0,0.00") }}</td>
              <td>{{ numeral(cre.cpm || 0).format("$0.00") }}</td>
              <td>{{ numeral(cre.estimated_user_value).format("0.000") }}</td>
              <td>{{ numeral(cre.estimated_visit_value).format("0.000") }}</td>
              <td>{{ calc_cpc(cre) }}</td>
              <td>{{ numeral(cre.estimated_margin).format("0.0%") }}</td>
              <td>{{ numeral(cre.ctr || 0).format("0.00") }}%</td>
              <td>
                {{ numeral(cre.bounce < 0 ? 0 : cre.bounce).format("0.0%") }}
              </td>
              <td>
                {{
                  numeral(cre.land_bounce < 0 ? 0 : cre.land_bounce).format(
                    "0.0%"
                  )
                }}
              </td>
              <td v-if="hasVCTR">
                {{ numeral(cre.vctr || 0).format("0.00") }}%
              </td>
              <td>
                {{
                  cre.cost_per_result !== null
                    ? numeral(cre.cost_per_result || 0).format("$0,0.00")
                    : "N/A"
                }}
              </td>
              <td>
                {{
                  cre.results !== null
                    ? numeral(cre.results).format("0,0")
                    : "N/A"
                }}
              </td>
              <td class="quality_ranking">{{ cre.quality_ranking }}</td>
              <td class="active-col">
                <b-button
                  v-model="cre.active"
                  name="creative_activity_switch"
                  @click="update_cre_activity(cre)"
                  v-bind:class="{ greybtn: !cre.active }"
                  class="activity-btn btn-sm rounded-pill"
                  >{{ cre.active ? "On" : "Off" }}</b-button
                >
                <b-badge v-if="status_exeption(cre)" pill variant="warning">{{
                  cre.effective_status
                }}</b-badge>
              </td>
              <td class="active-col">
                <b-button
                  v-model="cre.active"
                  name="duplicate_ad_action"
                  @click="duplicate_ad(cre)"
                  class="leading-none"
                >
                  <feather-icon
                    title="Duplicate Ad"
                    class="cursor-pointer"
                    icon="CopyIcon"
                  />
                </b-button>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
      <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
    </table>
  </div>
</template>

<script>
import numeral from "numeral";

export default {
  name: "PerfCreatives",
  props: [
    "baseUrl",
    "entityType",
    "entity",
    "modalSelectedDate",
    "campaignTimezone",
  ],
  data() {
    return {
      numeral: numeral,
      adAccountTimezone: null,
      submitError: null,
      adsets: [],
      newAdName: "",
    };
  },
  watch: {
    modalSelectedDate: {
      immediate: true,
      deep: true,
      handler() {
        this.load();
      },
    },
  },
  computed: {
    timezonesMatch: function () {
      return this.adAccountTimezone == this.campaignTimezone;
    },
    hasVCTR: function () {
      return (
        this.adsets[0] &&
        Object.keys(this.adsets[0].creatives[0] || {}).includes("vctr")
      );
    },
  },
  methods: {
    load() {
      this.creatives = [];
      this.$http
        .get(`${this.baseUrl}/creatives`, {
          params: {
            start: this.modalSelectedDate.start,
            end: this.modalSelectedDate.end,
          },
        })
        .then((res) => {
          this.adsets = res.data.adsets;
          this.adAccountTimezone = res.data.ad_account_timezone;
        })
        .catch((ex) => {
          this.submitError = ex.response.data.error;
        });
    },
    update_cre_activity(cre) {
      this.submitError = "";
      if (confirm("are you sure you want to change creative activity?")) {
        this.$http
          .get(`${this.host}/ads/${cre.id}/toggle_ad_status`)
          .then(() => {
            cre.active = !cre.active;
          })
          .catch((ex) => {
            this.submitError = `Updating creative status failed.\n${
              ex.response.data.error || ex.response.data
            }`;
          });
      }
    },
    duplicate_ad(cre) {
      this.submitError = "";
      if (confirm("are you sure you want to duplicate this ad?")) {
        this.$http
          .post(`${this.host}/ads/${cre.id}/duplicate`)
          .then((res) => {
            this.$bvToast.toast(
              `New ad name is ${res.data.name}. Wait for reload...`,
              {
                title: "Ad duplicated successfully!",
                autoHideDelay: 40000,
                toaster: "ad-toaster",
                variant: "primary",
              }
            );
            this.load();
          })
          .catch((ex) => {
            this.submitError = `Duplicate ad failed.\n${
              ex.response.data.error || ex.response.data
            }`;
          });
      }
    },
    calc_cpc(cre) {
      let cpc =
        cre.clicks == 0
          ? "~"
          : this.numeral(cre.spend / cre.clicks).format("$0.000");
      cre.cpc = cpc;
      return cre.cpc;
    },
    status_exeption(cre) {
      const APPROVED_VALS = ["PAUSED", "ACTIVE", "APPROVED", "ACCEPTED"];

      return !APPROVED_VALS.includes(cre.effective_status);
    },
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

.no-top-border td,
table {
  border-top: none !important;
}

table,
td,
tr {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}
</style>
<style>
.thumbnail.col-2.img-col {
  display: flex;
  height: fit-content;
  justify-content: space-around;
}

.thumbnail img {
  width: 5rem;
}

.img-col.thumbnail:hover {
  transform: scale(3) translate(35%, 20%);
  transition: transform 0.5s;
}

.quality_ranking {
  word-break: break-all;
}

.thumbnail video {
  width: 5rem;
}

div.switch.custom-control.custom-switch {
  display: flex;
  justify-content: flex-end;
}

.switch.custom-control.custom-switch p {
  line-height: 200%;
  margin: 0;
}

.custom-control-input:checked ~ label.custom-control-label::before {
  background-color: rgb(0, 168, 0);
  border-color: rgb(0, 168, 0);
}

.disabled {
  opacity: 0.5;
}

.alert-danger {
  white-space: pre-line;
}

button.activity-btn {
  background: #27c414;
  border: #27c414;
}

button.activity-btn:hover {
  background-color: green;
}

button.activity-btn.greybtn {
  background: grey;
  border: grey;
}

button.activity-btn.greybtn:hover {
  background-color: rgb(73, 73, 73);
}

.ad-name-cell {
  min-width: 30vw;
}

.ad-name {
  word-break: break-all;
}
</style>
