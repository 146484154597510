<template>
  <div v-if="isLoading" class="my-4">
    <b-spinner label="Loading..."></b-spinner>
  </div>
  <table v-else class="text-center table">
    <thead>
      <tr>
        <template v-for="header in Object.values(this.metricsColumns)">
          <th :key="header">{{ header["label"] }}</th>
        </template>
      </tr>
    </thead>
    <tbody>
      <tr class="total-row">
        <template v-for="(value, key) in metricsColumns">
          <td :key="key">
            {{
              key == "key"
                ? "TOTAL"
                : numeral(totals[key] || 0).format(value["format"])
            }}
          </td>
        </template>
      </tr>

      <template v-for="oSmetric in metrics">
        <tr :key="oSmetric['key']" class="os-row">
          <template v-for="(value, key) in metricsColumns">
            <td :key="key">
              {{
                key == "key"
                  ? oSmetric["key"].toUpperCase()
                  : numeral(oSmetric[key] || 0).format(value["format"])
              }}
            </td>
          </template>
        </tr>
        <template v-for="browserMetric in oSmetric['by_browser']">
          <tr
            :key="`${oSmetric['key']}-${browserMetric['key']}`"
            class="browser-row"
          >
            <template v-for="(value, key) in metricsColumns">
              <td :key="key" class="pl-6">
                {{
                  key == "key"
                    ? browserMetric["key"].charAt(0).toUpperCase() +
                      browserMetric["key"].slice(1)
                    : numeral(browserMetric[key] || 0).format(value["format"])
                }}
              </td>
            </template>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import numeral from "numeral";

export default {
  name: "PerfOSAndBrowser",
  props: ["baseUrl", "modalSelectedDate", "campaignTimezone"],
  data() {
    return {
      metrics: [],
      totals: [],
      metricsColumns: {
        key: {
          label: "Publisher",
          format: null
        },
        impressions: {
          label: "Reach",
          format: "0,0"
        },
        lands: {
          label: "Visits",
          format: "0,0"
        },
        visits: {
          label: "Sessions",
          format: "0,0"
        },
        land_bounce: {
          label: "Bounce %",
          format: "0.0%"
        },
        imps_per_lands: {
          label: "PPS",
          format: "0,0.0"
        },
        revenue: {
          label: "Revenue",
          format: "$0,0.000"
        },
        land_user_value: {
          label: "Land User Value",
          format: "$0,0.0000"
        },
        rpm: {
          label: "RPM",
          format: "$0,0.000"
        },
        units: {
          label: "Items",
          format: "0,0.0"
        }
      },
      numeral: numeral,
      isLoading: true
    };
  },
  mounted() {
    this.loadOSAndBrowserMetrics();
  },
  watch: {
    modalSelectedDate: {
      deep: true,
      handler() {
        this.loadOSAndBrowserMetrics();
      }
    }
  },
  methods: {
    loadOSAndBrowserMetrics() {
      this.isLoading = true;
      this.$http
        .get(`${this.baseUrl}/os_and_browser`, {
          params: {
            start: this.modalSelectedDate.start,
            end: this.modalSelectedDate.end,
            timezone: this.campaignTimezone
          }
        })
        .then(res => {
          this.metrics = res.data.metrics;
          this.totals = res.data.totals;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.total-row {
  background-color: lightgray;
  font-size: 13px;
  font-weight: bold;
}

.os-row {
  background-color: #e8e8e8;
}
</style>
