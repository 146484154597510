<template>
<div>
    <b-button @click="loadUnits()" :id="`popover-units-${id}`">{{ content }}</b-button>
    <b-popover :target="`popover-units-${id}`" triggers="hover focus" custom-class="popover-custom-body">
        <h6>Item Dropouts</h6>
        <table>
            <tbody>
                <tr :key="key + id" v-for="(content, key) in units">
                    <td>Item {{ key }}</td>
                    <td>{{ numeral(content).format('0.0') }}%</td>
                </tr>
            </tbody>
        </table>
    </b-popover>
</div>
</template>

<script>
import numeral from 'numeral';

export default {
  props: {
    campaign_id: Number,
    utm_term: String,
    content:  String,
    timezone: String,
    start: Object,
    end: Object
  },
  name: 'UnitsTooltip',
  data() {
    return {
        units:   {},
        numeral: numeral
    }
  },
  computed: {
    id: function() {
      return this.utm_term ? this.utm_term : this.campaign_id;
    }
  },
  methods: {
    loadUnits: function() {
      if (!this.campaign_id) return;

      const params = {
        start: this.start,
        end: this.end,
        timezone: this.timezone
      };
      if (this.utm_term) {
        params.utm_term = this.utm_term;
      }
      this.units = {};
      this.$http
        .get(`${this.host}/campaigns/${this.campaign_id}/campaign_items_drop`, {
          params: params
        })
        .then(res => {
          this.units = this.filterInsignificantDrops(res.data.items);
        });
    },
    filterInsignificantDrops: function(items) {
      for (const [item, drop] of Object.entries(items)) {
        if (drop < 0.1) delete items[item];
      }
      return items;
    }
  }
}

</script>

<style scoped>
  button[id^='popover-units'] {
    border: 0px;
    background-color: transparent;
    color: black;
    font-size: 0.79rem;
  }
</style>

