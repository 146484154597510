<template>
  <div v-if="isLoaded">
    <b-form-textarea id="note" v-model="content" placeholder="Write Something..." rows="3" max-rows="6"></b-form-textarea>
    <b-button :disabled="isSaving" @click="save" variant="success">{{ isSaving ? 'Saving..' : 'Save' }}</b-button>
    <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
  </div>
</template>

<script>

export default {
  name: "PerfNote",
  props: ["entity"],
  data() {
    return {
      content: null,
      isLoaded: false,
      isSaving: false,
      submitError: null
    };
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {  
      this.$http.get(`${this.host}/notes`, {
        params: {
          campaign_id: this.entity.id
        }
      })
      .then(res => {
        this.content = ''
        this.isLoaded = true;
        if (res.data.note) {
          this.content = res.data.note.content;
        }
      })
      .catch(ex => {
        this.isLoaded = true;
        this.content = ''
        this.submitError = ex.response.data.error;
      });
    },
    save() { 
      this.isSaving = true;
      this.$http.put(`${this.host}/notes/create_or_update`, {
            content: this.content ? this.content : null,
            campaign_id: this.entity.id
      })
      .then(res => {
        this.status = res.data.status;
        this.$eventHub.$emit("syncNoteIcons", { hasNote: this.content !== "", campaignName: this.entity.name})
        this.isSaving = false;
      })
      .catch(ex => {
        this.submitError = ex.response.data.error;
        this.isSaving = false;
      });
    }
  }  
}
</script>
<style scoped>


#note {
  margin-top: 1rem;
}

</style>
