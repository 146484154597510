<template>
  <div class="adsets">
    <b-card :key="`${dupKey}-${adset.serialNum}`" v-for="adset in adsets">
      <template v-slot:header>
        <h6 v-b-toggle.adsets-1 class="mb-0 w-full text-left">
          {{ adset.name }}
        </h6>
      </template>
      <template>
        <b-collapse id="adsets-1" class="mt-2">
          <b-container>
            <b-form-group :disabled="disableAllFields">
              <b-form-row>
                <b-input-group prepend="Max Bid" append="$">
                  <label for="max_bid" hidden>Max bid</label>
                  <b-form-input
                    v-model="adset.max_bid"
                    :id="`max_bid-${dupKey}-${adset.serialNum}`"
                    :required="dup.objective !== 'OUTCOME_LEADS'"
                  ></b-form-input>
                </b-input-group>

                <b-input-group
                  v-if="(platformOptions.daily_budget || {}).adset"
                  prepend="Daily Budget"
                  append="$"
                >
                  <label for="daily_budget" hidden>daily_budget</label>
                  <b-form-input
                    v-model="adset.daily_budget"
                    :id="`Daily Budget-${dupKey}-${adset.serialNum}`"
                    required
                  ></b-form-input>
                </b-input-group>
              </b-form-row>
              <b-form-row>
                <b-input-group
                  v-if="platform == 'facebook'"
                  prepend="Automatic Placement"
                >
                  <label for="auto_placement" hidden></label>
                  <b-form-checkbox
                    @input="watchAutoPlacement(adset)"
                    switch
                    size="lg"
                    v-model="adset.auto_placement"
                    class="align-middle form-control"
                  />
                </b-input-group>
                <b-input-group
                  v-if="platform == 'facebook'"
                  prepend="Attribution Window"
                >
                  <label for="attr_window" hidden></label>
                  <b-form-select
                    v-model="adset.attr_window"
                    :id="`attr_window-${dupKey}-${adset.serialNum}`"
                  >
                    <option :key="value" :value="value" v-for="value in [1, 7]">
                      {{ value }}-day click
                    </option>
                  </b-form-select>
                </b-input-group>
                <b-input-group
                  prepend="Custom Conversions"
                  v-if="isCustomConversion"
                >
                  <label :for="conversionList" hidden>Custom Conversions</label>
                  <b-form-select
                    v-model="adset.custom_conversion"
                    :id="`custom_conversion-${dupKey}-${adset.serialNum}`"
                  >
                    <option
                      :key="name"
                      :value="value"
                      v-for="(value, name) in conversionList"
                    >
                      {{ name }}
                    </option>
                  </b-form-select>
                </b-input-group>

                <b-input-group prepend="Ages">
                  <label :for="adset.age_min" hidden>Ages</label>
                  <b-select v-model="adset.age_min" class="form-control">
                    <option
                      :key="opt"
                      :value="opt"
                      v-for="opt in platformOptions.age_min"
                    >
                      {{ opt ? opt : "Any" }}
                    </option>
                  </b-select>
                  <b-input-group-append
                    ><b-input-group-text
                      >to</b-input-group-text
                    ></b-input-group-append
                  >
                  <b-select v-model="adset.age_max" class="form-control">
                    <option
                      :key="opt"
                      :value="opt"
                      v-for="opt in maxAge(adset)"
                    >
                      {{ opt ? opt : "Any" }}
                    </option>
                  </b-select>
                </b-input-group>

                <b-input-group prepend="Gender">
                  <label :for="adset.gender" hidden>Gender</label>
                  <b-select v-model="adset.gender" class="form-control">
                    <option
                      :key="opt.name"
                      :value="opt.code"
                      v-for="opt in platformOptions.gender"
                    >
                      {{ opt.name }}
                    </option>
                  </b-select>
                </b-input-group>
              </b-form-row>

              <b-form-row>
                <b-input-group prepend="Device Platform">
                  <label :for="platformOptions.platforms" hidden
                    >Device Platform</label
                  >
                  <multiselect
                    class="form-control custom-multiselect"
                    v-model="adset.device_platforms"
                    :id="`device_platforms-${dupKey}-${adset.serialNum}`"
                    v-if="platformOptions.platforms"
                    label="name"
                    track-by="code"
                    :multiple="true"
                    :searchable="true"
                    :options="platformOptions.platforms"
                    @input="devicePlatformValidations(adset)"
                    :disabled="adset.auto_placement"
                  ></multiselect>
                </b-input-group>

                <b-input-group prepend="Countries">
                  <label :for="platformOptions.groupedCountryOptions" hidden
                    >Countries</label
                  >
                  <multiselect
                    class="form-control custom-multiselect"
                    v-model="adset.countries"
                    :id="`countries-${dupKey}-${adset.serialNum}`"
                    v-if="platformOptions.groupedCountryOptions"
                    label="name"
                    track-by="code"
                    group-values="countries"
                    group-label="name"
                    :group-select="true"
                    :multiple="true"
                    :searchable="true"
                    :options="platformOptions.groupedCountryOptions"
                  ></multiselect>
                </b-input-group>

                <b-input-group
                  prepend="Languages"
                  v-if="platformOptions.locales"
                >
                  <label :for="platformOptions.locales" hidden>Languages</label>
                  <multiselect
                    class="form-control custom-multiselect"
                    v-model="adset.locales"
                    :id="`locales-${dupKey}-${adset.serialNum}`"
                    label="name"
                    track-by="code"
                    :multiple="true"
                    :searchable="true"
                    :options="platformOptions.locales"
                  ></multiselect>
                </b-input-group>

                <b-input-group
                  prepend="Browsers"
                  v-if="platformOptions.browsers"
                >
                  <label :for="platformOptions.browsers" hidden>Browsers</label>
                  <multiselect
                    class="form-control custom-multiselect"
                    v-model="adset.browsers"
                    :id="`browsers-${dupKey}-${adset.serialNum}`"
                    label="name"
                    track-by="code"
                    :multiple="true"
                    :searchable="true"
                    :options="platformOptions.browsers"
                  ></multiselect>
                </b-input-group>

                <b-input-group
                  prepend="Operating Systems"
                  v-if="platformOptions.operating_systems"
                >
                  <label :for="platformOptions.operating_systems" hidden
                    >Operating Systems</label
                  >
                  <multiselect
                    class="form-control custom-multiselect"
                    v-model="adset.operating_systems"
                    :id="`operating_systems-${dupKey}-${adset.serialNum}`"
                    label="name"
                    track-by="code"
                    :multiple="true"
                    :searchable="true"
                    :options="platformOptions.operating_systems"
                  ></multiselect>
                </b-input-group>

                <b-input-group prepend="Custom Name">
                  <label :for="adset.custom_name" hidden>Custom Name</label>
                  <b-form-input v-model="adset.custom_name"></b-form-input>
                </b-input-group>
              </b-form-row>
            </b-form-group>
          </b-container>
        </b-collapse>
      </template>
    </b-card>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {
  VALIDATION,
  UTILS,
  COMPUTED_PROPS,
  NAME_GENERATORS,
  SERIALIZE,
  FB_ATTR_WINDOW_DEFAULT,
} from "../../utils/campaign_form.js";

export default {
  name: "AdsetsMassDup",
  props: {
    dupKey: String,
    dup: Object,
    isCustomConversion: Boolean,
    conversionList: Object,
    gurl: Function,
    platformOptions: Object,
    disableAllFields: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      adsets: this.value,
    };
  },

  updated() {
    this.$emit("input", this.adsets);
  },

  mounted() {
    let adsets = UTILS.clone(this.dup.adsets);
    this.platform = this.dup.platform;

    this.adsets = adsets.map((adset) => {
      adset.loadedData.locales = SERIALIZE.targetToPlatformOption(
        this.platform,
        adset.loadedData.locales
      );
      adset.loadedData.browsers = SERIALIZE.targetToPlatformOption(
        this.platform,
        adset.loadedData.browsers
      );
      adset.loadedData.countries = SERIALIZE.targetToPlatformOption(
        this.platform,
        adset.loadedData.countries
      );
      adset.loadedData.device_platforms = SERIALIZE.targetToPlatformOption(
        this.platform,
        adset.loadedData.device_platforms
      );
      adset.loadedData.operating_systems = SERIALIZE.targetToPlatformOption(
        this.platform,
        adset.loadedData.operating_systems
      );
      return { ...adset.loadedData, serialNum: adset.serialNum };
    });

    this.$emit("toObject", () => this.toObject());
  },
  watch: {
    $data: {
      deep: true,
      handler() {
        this.adsets.forEach((adset) => {
          adset.name = NAME_GENERATORS.adset({
            ...adset,
            platform: this.platform,
          });
        });
      },
    },
  },
  methods: {
    watchAutoPlacement(adset) {
      if (adset.auto_placement) adset.device_platforms = [];
    },
    devicePlatformValidations(adset) {
      adset.device_platforms = VALIDATION.device_platforms(
        adset.device_platforms
      );
    },
    maxAge(adset) {
      return COMPUTED_PROPS.maxAge(this.platformOptions.age_max, adset.age_min);
    },
    toObject() {
      const adsetObjects = this.toObj();
      const adsetNames = this.adsets.map((adset) =>
        NAME_GENERATORS.adset({ ...adset, platform: this.platform })
      );
      for (const adsetIndex in adsetObjects) {
        adsetObjects[adsetIndex].name = adsetNames[adsetIndex];
        const adsetObj = adsetObjects[adsetIndex];
        for (const adObj of adsetObj.creatives) {
          const creative = this.adsets[adsetIndex].creatives.find(
            (ad) => ad.name === adObj.name
          );
          Object.assign(adObj, { id: creative.id });
        }
      }
      return adsetObjects;
    },
    toObj() {
      let dupName = this.dup.name;

      let adsets = this.adsets.map((adset) => {
        let creatives = adset.creatives.map((ad) => {
          ad.name = NAME_GENERATORS.ad(dupName, adset.serialNum, ad.serialNum);

          return {
            gurl: this.gurl(dupName, ad.name),
            name: ad.name,
            text: ad.text,
            headline: ad.headline,
            media_key: ad.media_key,
            signed_media: ad.signed_media,
            media_bucket: "imgn-publishers",
            call_to_action: ad.call_to_action,
          };
        });

        return {
          name: adset.name,
          custom_name: adset.custom_name,
          age_min: adset.age_min,
          age_max: adset.age_max,
          gender: adset.gender,
          device_platforms: adset.device_platforms.map((p) => p.code),
          countries: adset.countries.map((c) => c.code),
          locales: adset.locales.map((l) => l.code),
          browsers: adset.browsers.map((l) => l.code),
          operating_systems: adset.operating_systems.map((l) => l.code),
          max_bid: adset.max_bid,
          daily_budget: adset.daily_budget,
          custom_conversion: adset.custom_conversion,
          goal: adset.goal,
          attr_window: adset.attr_window || FB_ATTR_WINDOW_DEFAULT,
          auto_placement: adset.auto_placement,
          sb_preset_id: adset.sb_preset_id,
          metadata: adset.metadata,
          creatives: creatives,
        };
      });

      return UTILS.clone(adsets);
    },
  },
  components: {
    Multiselect,
  },
};
</script>
<style>
.multiselect__tag {
  background: #e02866 !important;
}

.multiselect__option--highlight {
  background: #e02866 !important;
}

.multiselect__option--highlight:after {
  background: #e02866 !important;
}
</style>
<style scoped>
div.adsets {
  width: 100%;
}

.form-control {
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  height: inherit;
}

form.form-inline {
  flex-direction: column;
}

div.card {
  margin: 0.5rem;
}

div.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card div.card-body {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

div.card-body .input-group {
  padding: 0.5rem;
}

button.delete-btn {
  color: red;
  font-weight: 600;
  font-size: 1rem;
  background: none;
  border: none;
}

.bold-red {
  font-weight: 700;
  color: red;
}

.align-middle {
  padding: 0.2rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
