<template>
  <div>
    <button class="btn btn-primary my-2 btn-sm" @click="clearAll()">Clear all</button>
    <table class="text-center table table-bordered table-responsive-md">
      <thead>
        <th>
          Triggered on
        </th>
        <th>
          Rule
        </th>
      </thead>
      <tbody>
        <tr v-if="notiesComputed.length == 0">
          <td colspan="3">
            No notifications
          </td>
        </tr>
        <tr v-else v-for="notify in notiesComputed" :key="notify.created_at">
          <td>
            {{ moment(notify.created_at).utc().format('MMMM Do YYYY, h:mm:ss a') }}
          </td>
          <td>
            {{ notify.message}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: "PerfNotifications",
    props: ["entity", "notifications"],
    data() {
      return {
        noties: [],
        moment: moment
      };
    },
    computed:
    {
      notiesComputed()
      {
        return this.noties.reverse();
      }
    },
    mounted() {
      this.load();
      this.resetBadges();
    },
    watch: {

    },
    methods: {
      resetBadges() {
        this.$http.get(`${this.host}/campaigns/${this.entity.id}/notifications/reset`).then(() => {
          this.$eventHub.$emit("resetBadges", { campaign: this.entity })
        })
        .catch(() => {

        });
      },
      clearAll() {
        this.$http.delete(`${this.host}/campaigns/${this.entity.id}/notifications`).then(() => {
          this.noties = [];
        })
        .catch(() => {

        });
      },
      load() {
        this.$http.get(`${this.host}/campaigns/${this.entity.id}/notifications`)
        .then(res => {
          this.noties = res.data.notifications;
        })
        .catch(() => {

        });
      }
    }
  };
</script>

<style>

</style>
